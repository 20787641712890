::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.approved {
    color: #20845b;
  }
  
  .pending {
    color: #f04a00;
  }
  
  .decline {
    color: #dc3545;
  }
  
