.sb-sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #0000001a;
  border-radius: 0px 24px 24px 0px;
  flex-wrap: nowrap;
  .header-section {
    margin: 24px;
    @media (min-width: 1537px) {
      margin: 32px;
    }
    img {
      cursor: pointer;
    }
    button {
      padding-right: 0px;
      i {
        font-size: calc(var(--bs-body-font-size) + 4px);
      }
    }
    .client-logo {
      width: 170px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .sb-sidenav-menu {
    flex-grow: 1;
    .nav {
      flex-direction: column;
      flex-wrap: nowrap;
      .nav-link {
        margin: 4px 24px;
        height: 48px;
        @media (min-width: 1537px) {
          margin: 4px 32px;
          height: 56px;
        }
        display: flex;
        align-items: center;
        position: relative;
        text-align: left;
        padding: 12px 24px;
        font-size: var(--bs-body-font-size);
        font-weight: var(--bs-body-font-weight-medium);
        line-height: 20px;
        letter-spacing: 0.32px;
        border-radius: 14px;
        .sb-nav-link-icon {
          display: flex;
          align-items: center;
          i {
            font-size: calc(var(--bs-body-font-size) + 10px);
          }
        }
        span {
          letter-spacing: 0.16px;
          font-size: var(--bs-body-font-size);
          font-weight: var(--bs-body-font-weight-medium);
          padding-left: 16px;
        }
        &.active {
          font-weight: bold;
          letter-spacing: 0px;
        }
      }
    }
  }
  .sb-sidenav-footer {
    margin: 0 32px;
    padding-top: 8px;
    text-align: right;
    flex-shrink: 0;
    position: relative;
    .footer-info {
      border-top: 1px solid #dee2e6;
      padding-top: 24px;
    }
    .small-txt {
      font-size: calc(var(--bs-body-font-size) - 5px);
      letter-spacing: 0.22px;
      color: #696969;
    }
    img {
      width: 128px;
      height: 24px;
    }
  }
}
.sb-nav-fixed {
  #layoutSidenav {
    #layoutSidenav_nav {
      width: 300px;
      height: 100%;
      z-index: 1040;
      transition: 0.2s ease-in-out;
      @media (min-width: 1537px) {
        width: 348px;
      }
      .sb-sidenav {
        padding: 0 0 32px 0;
      }
    }
  }
}
.client-icon {
  display: none;
}
.w40{
  width: 40px;
  height: 40px;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-sidenav-collapse-arrow {
  display: inline-block;
  margin-left: auto;
  transition: transform 0.2s ease;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link.collapsed .sb-sidenav-collapse-arrow {
  transform: rotate(-90deg);
}
.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested {
  margin-left: 1.5rem;
  flex-direction: column;
  margin-right: 1.5rem;
  background-color: white;
  color: #000;
  border-radius: 10px;
}
.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested .nav-link {
  color: #000;
  border-bottom: 1px #000;
}
.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested .nav-link:hover {
  color: #4e73df !important;
  border-radius: 10px;
  border-bottom: 1px solid #4e73df;
}
.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested .nav-link:active {
  color: #4e73df !important;
  border-radius: 10px;
  border-bottom: 1px solid #4e73df;
}
#layoutSidenav {
  display: flex;
}
#layoutSidenav #layoutSidenav_nav {
  flex-basis: 348px;
  flex-shrink: 0;
  transition: transform 0.2s ease-in-out;
  z-index: 1038;
  transform: translateX(-348px);
}
#layoutSidenav #layoutSidenav_content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  flex-grow: 1;
  min-height: calc(100vh - 72px);
  margin-left: -300px;
  @media (min-width: 1537px) {
    margin-left: -348px;
  }
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
  transform: translateX(0);
  title {
    display: block;
  }
}
.sb-sidenav-toggled #layoutSidenav .overlay-backdrop {
  display: block;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: #000;
  z-index: 1039;
  opacity: 0.6;
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
  @media (min-width: 1024px) {
    margin-left: -220px;
  }
  @media (min-width: 1537px) {
    margin-left: -268px;
  }
}
@media (min-width: 1024px) {
  #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
  }
  #layoutSidenav #layoutSidenav_content {
    margin-left: 0;
    transition: 0.2s ease-in-out;
  }
  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
    transition: 0.2s ease-in-out;
    width: 80px;
    .sb-sidenav {
      .header-section {
        flex-direction: column;
        margin: 16px auto;
        .client-logo {
          display: none;
        }
        .client-icon,
        .admin-cls-badge {
          display: block;
        }

        button {
          margin-bottom: 8px;
          padding-right: 24px;
          i {
            font-size: calc(var(--bs-body-font-size) + 10px);
          }
        }
      }
      .sb-sidenav-menu {
        .nav {
          .nav-link {
            margin: 4px auto;
            padding: 12px;
            span {
              display: none;
            }
          }
        }
      }
    }
    .sb-sidenav-footer {
      display: none;
    }
  }

  .sb-sidenav-toggled #layoutSidenav .overlay-backdrop {
    display: none;
  }
}
.sb-nav-fixed .sb-topnav {
  z-index: 1039;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
  overflow-y: auto;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_content {
  padding-left: 300px;
  top: 72px;
  @media (min-width: 1537px) {
    padding-left: 348px;
  }
  @media (max-width: 575.98px) {
    top: 56px;
  }
}

.sb-sidenav-light {
  background-color: #f8f9fa;
  color: #212529;
}
.sb-sidenav-light .sb-sidenav-menu .sb-sidenav-menu-heading {
  color: #adb5bd;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link {
  color: #212529;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  color: #adb5bd;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
  color: #adb5bd;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link:hover .sb-nav-link-icon {
  color: #0d6efd;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link.active {
  color: #0d6efd;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
  color: #0d6efd;
}
.sb-sidenav-light .sb-sidenav-footer {
  background-color: #e9ecef;
}

.sb-sidenav-dark {
  background-color: #212529;
  color: rgba(255, 255, 255, 0.5);
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link {
  color: rgba(255, 255, 255, 0.8);
  /* font-size: 10.rem !important; */
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover .sb-nav-link-icon {
  color: #fff;
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link.sb-nav-link-icon:hover {
  color: #fff;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
  color: rgba(255, 255, 255, 0.25);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover .sb-sidenav-collapse-arrow {
  color: #fff;
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link.active {
  color: #fff;
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover {
  color: #fff;
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
  color: #fff;
}
.sb-sidenav-dark .sb-sidenav-footer {
  background-color: #fff;
  color: #000;
}
