@import "./../../scss/breakpoints";

.authentication-main {
    min-height: 100vh;
    background: #013775;
    padding: 12px 0;
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }

    .authentication-card {
        border-radius: 24px;
        transition: ease-in-out 0.2s;
        @include lg {
            align-items: center;
            justify-content: center;
            border-radius: 0;
        }
    }
    .authentication-left {
        width: 456px;
        background: #eee;
        border-radius: 24px 0 0 24px;

        @include miniheight {
            width: 380px;
        }
        @include xl {
            width: 330px;
        }
        @include lg {
            display: none;
            border-radius: 0;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top center;
            border-radius: 24px 0 0 24px;
            transition: ease-in-out 0.2s;
            @include lg {
                border-radius: 0;
            }
        }
    }
    .authentication-right {
        width: 616px;
        padding: 48px 56px;
        background: #fff;
        border-radius: 0 24px 24px 0;
        &.forgot-password {
            border-radius: 24px;
        }
        @include miniheight {
            width: 580px;
            padding: 36px 48px;
        }
        @include xl {
            width: 550px;
            padding: 40px 50px;
        }
        @include lg {
            border-radius: 20px;
            width: calc(100% - 80px);
        }
        @include sm {
            padding: 30px;
            width: calc(100% - 60px);
        }
    }

    .authentication-title {
        @include sm {
            order: 1;
        }
        h1 {
            font-size: 32px;
            line-height: 44px;
            font-weight: 600;
            letter-spacing: 0.32px;
            @include md {
                font-size: calc(var(--bs-body-font-size) + 14px);
                line-height: 34px;
            }
            @include sm {
                font-size: calc(var(--bs-body-font-size) + 10px);
            }
        }
        span {
            font-size: var(--bs-body-font-size);
            line-height: 20px;
            margin-top: 4px;
            letter-spacing: 0.32px;
            @include md {
                font-size: calc(var(--bs-body-font-size) - 4px);
            }
            @include sm {
                font-size: calc(var(--bs-body-font-size) - 2px);
                margin-top: 2px;
            }
        }
    }
    .authentication-logo {
        height: 56px;
        margin-left: 12px;
        @include md {
            height: 60px;
        }
        @include sm {
            order: 0;
            margin-left: 0;
            margin-bottom: 20px;
        }
        img {
            height: 100%;
        }
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.theme-freedom .authentication-main::after {
    background: url("../../assets/img/logo/freedom/freedom-bg.svg") top left no-repeat;
    background-size: cover;
}

.theme-healthsun .authentication-main::after {
    background: url("../../assets/img/logo/healthsun/healthsun-bg.svg") top left no-repeat;
    background-size: cover;
}

.theme-optimum .authentication-main::after {
    background: url("../../assets/img/logo/Optimum/optimum-bg.svg") top left no-repeat;
    background-size: cover;
}

.authentication-footer {
    margin-top: 32px;
    @include md {
        flex-wrap: wrap;
    }
    @include sm {
        align-items: center;
        justify-content: center;
        margin-top: 24px;
    }
    .terms-conditions {
        position: relative;
        top: 6px;
        cursor: pointer;
        @include md {
            flex: 1 1 100%;
        }
        @include sm {
            align-items: center;
            justify-content: center;
        }
    }
    p {
        color: #696969;
        font-size: calc(var(--bs-body-font-size) - 4px);
        line-height: 16px;
        letter-spacing: 0.24px;
        position: relative;
        top: 2px;
        @include sm {
            top: 0;
            margin-top: 24px;
            order: 2;
        }
    }
    img {
        // margin-left: 32px;
        @include sm {
            margin-left: 0;
            margin-top: 16px;
        }
    }

    .poweredby-image {
        @include md {
            position: relative;
            top: -4px;
        }
        @include sm {
            order: 1;
        }
    }
}

.form-label-main {
    .svg-inline--fa {
        height: 14px;
        vertical-align: top;
        cursor: pointer;
        path {
            fill: #7d8998;
        }
    }
}

.member-id-card {
    position: absolute;
    bottom: 90px;
    right: -16px;
    background: #fff;
    border: 1px solid #0054a4;
    padding: 26px;
    border-radius: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
    width: 560px;
    z-index: 1;

    @include xl {
        width: 500px;
    }

    @include lg {
        width: 460px;
    }

    @include sm {
        width: 100%;
        bottom: 80px;
        right: 0;
        padding: 16px;
    }

    &::before {
        content: "";
        position: absolute;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 10px solid #fff;
        bottom: -10px;
        right: 210px;
        z-index: 1;
    }
    &::after {
        content: "";
        position: absolute;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 13px solid #8faac4;
        bottom: -13px;
        z-index: 0;
        right: 208px;
    }

    .member-id-header {
        margin-bottom: 12px;
        p {
            font-size: var(--bs-body-font-size);
            line-height: 24px;

            @include xl {
                font-size: 15px;
            }

            @include lg {
                font-size: calc(var(--bs-body-font-size) - 2px);
            }

            @include sm {
                font-size: calc(var(--bs-body-font-size) - 3px);
                line-height: 20px;
            }
        }
        i {
            font-size: calc(var(--bs-body-font-size) + 4px);
            cursor: pointer;
        }
        .svg-inline--fa {
            cursor: pointer;
            height: 20px;
        }
        > span {
            margin-left: 20px;
        }
    }

    .member-id-img {
        img {
            width: 100%;
        }
    }
}

.stepper {
    .step {
        position: relative;

        @include xs {
            flex-direction: column;
        }

        &:not(:last-child)::after {
            content: "";
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            width: 32px;
            height: 1px;

            @include xs {
                right: 12px;
                top: auto;
                transform: translateY(0);
                width: 20px;
                bottom: 10px;
            }
        }
        span {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            font-size: calc(var(--bs-body-font-size) - 2px);
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;

            @include xs {
                font-size: calc(var(--bs-body-font-size) - 4px);
                width: 22px;
                height: 22px;
            }
        }
        label {
            margin-left: 12px;
            font-size: var(--bs-body-font-size);
            font-weight: var(--bs-body-font-weight-medium);

            @include sm {
                font-size: calc(var(--bs-body-font-size) - 2px);
            }

            @include xs {
                margin-left: 0;
                margin-top: 4px;
            }
        }
        &.active {
            label {
                font-weight: 600;
            }
        }
        &.disabled {
            cursor: not-allowed;
            span,
            label {
                cursor: not-allowed;
            }
        }
        &:not(:last-child) {
            padding-right: 56px;

            @include xs {
                padding-right: 44px;
            }
        }
    }
}
.signup-me-popup {
    .modal-header {
        .modal-title {
            margin-bottom: 0;
        }
        .btn-close {
            display: none;
        }
    }
    .modal-body {
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.16px;
    }
}
.form-check-input {
    width: 1.5em !important;
    height: 1.5em !important;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
