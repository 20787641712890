.pending {
    color: #fe7f00;
}
.decline {
    color: #ff243d;
}
.approved {
    color: #00960b;
}
.flex-center {
    display: flex;
    align-items: center;
    i {
        font-size: 20px;
        margin-right: 8px;
    }
}
.content-box {
    display: flex;
    align-items: flex-start;
    margin-bottom: 22px;
    word-break: break-word;
    .img-info {
        margin-top: 8px;
        i {
            font-size: 24px;
            color: #014694;
        }
        img {
            width: 24px;
            height: 24px;
        }
    }
    .content-info {
        padding-left: 10px;

        .label {
            text-align: left;
            font-size: 14px;
            letter-spacing: 0.28px;
            color: #757575;
        }
        .value {
            font-size: 16px;
            letter-spacing: 0.32px;
            color: #212121;
            &.link {
                display: flex;
                align-items: center;
                button {
                    padding: 0px;

                    border-radius: 50%;
                    i {
                        font-size: 24px;
                        color: #515977;
                    }
                }
                .link-ref {
                    text-decoration: underline;
                    color: #014694;
                    margin-right: 24px;
                }
            }
        }
    }
}
.text-custom-center {
    display: flex;
    align-items: center;
    .mr-24 {
        margin-right: 24px;
    }
}
.headers {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: #000000;
    padding-right: 68px;
    margin-bottom: 32px;
}
.divider {
    border: 1px solid #e6ebf0;
    margin-bottom: 32px;
    @media (min-width: 1400px) {
        margin-top: 12px;
        margin-bottom: 48px;
    }
}
.hidden-divider {
    border: 1px solid #e6ebf0;
    margin-bottom: 22px;
}
.second-divider {
    border: 1px solid #e6ebf0;
    margin-top: 4px;
    margin-bottom: 28px;
}
.sub-header {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    color: #000000;
    margin-bottom: 16px;
}
.mini-header {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #000000;
    margin-bottom: 16px;
}
.custom-mar {
    margin-bottom: 48px;
}

.custom-multiselect-checkbox {
    margin-bottom: 24px;
    .btn-group {
        flex-wrap: wrap;
    }
    .btn-outline-primary {
        border: 1px solid #bcc9d9;
        border-radius: 8px !important;
        margin: 0 12px 12px 0;
        font-size: 14px;
        padding: 6px 16px;
        letter-spacing: 0px;
        color: #014694;
        box-shadow: none !important;
        display: flex;
        align-items: center;
        i {
            font-size: 20px;
            margin-right: 4px;
        }
    }

    .btn-outline-primary:active {
        box-shadow: none;
        background: #f0f7ff;
        font-weight: 500;
        letter-spacing: 0px;
        color: #014694;
        border: none;
        border: 1px solid #fff;
    }
    .btn-outline-primary:hover {
        background: transparent;
        background: #f0f7ff;
        color: #014694;
        border: 1px solid #fff;
    }
    .btn-check:checked + .btn-outline-primary {
        background: #f0f7ff;
        color: #014694;
        border: none;
        border: 1px solid #fff;
    }
}
.textarea-info {
    margin-bottom: 22px;
    position: relative;
    .custom-textarea {
        background: #f8f9fa;
        border: 1px solid #8fa9c7;
        border-radius: 8px;
        padding: 16px 32px 16px 16px;
        font-size: 14px;
        letter-spacing: 0px;
        color: #7d8998;
        width: 100%;
        &:focus-visible {
            border: 1px solid #8fa9c7;
        }
    }
    i {
        position: absolute;
        color: #7d8998;
        position: absolute;
        color: #7d8998;
        right: 16px;
        top: 16px;
        font-size: 20px;
    }
}
.img-btn {
    display: inline-flex;
    align-items: center;
    i {
        font-size: 24px;
        margin-right: 8px;
    }
}
.theme-freedom .btn.btn-border-primary.decline {
    background: #f0f7ff;
}
.status-info {
    display: flex;
    align-items: center;
    .status {
        background: #fe7f0000 0% 0% no-repeat padding-box;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.24px;

        border-radius: 24px;
        padding: 2px 16px;

        margin-left: 12px;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        i {
            margin-right: 8px;
            font-size: 14px;
        }
        &.pending {
            border: 1px solid #f04a02;
            color: #f04a00;
        }
        &.approve {
            color: #20845b;
            border: 1px solid #20845b;
        }
        &.decline {
            color: #dc3545;
            border: 1px solid #dc3545;
        }
    }
    .dropdowninfo {
        margin-left: 4px;
        font-size: 20px;
        position: relative;
        top: 2px;
        color: #003b6a;
        .dropdown-menu {
            width: max-content;
            padding: 16px;
            .dropdown-header {
                color: #000;
                font-weight: 600;
            }
            li {
                padding: 4px 0;
                font-size: 14px;
                font-weight: 500;
            }
            .dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: inline-block;
                margin: 0 8px;
                background: #f04a00;
            }
        }
    }
}
.btn-disabled {
    cursor: not-allowed;
    pointer-events: inherit !important;
}
